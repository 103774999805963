.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0;
}

.svg {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.colourList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  line-height: 30px;
}
.colourSwatch {
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  flex: 0 0 auto;
  height: auto;
  margin: 1%;
  width: 8%;
  border-radius: 50%;
  position: relative;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    margin: 1%;
    width: 8%;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    margin: 2%;
    width: 29%;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin: 2%;
    width: 21%;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    margin: 2%;
    width: 16%;
  }
}
.colourSwatch:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.activeSwatch:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60%;
  height: 60%;
  margin: -30% 0 0 -30%;
  background-color: white;
  border-radius: 50%;
}
