/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "success": #8aa438,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@700&display=swap");
html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  background: #0f2843 url("./assets/base/bg2.png") repeat-x center top !important;
  background-size: 100%;
}

.footer-link {
  color: rgba(0, 0, 0, 0.5);
  padding: 0 10px;
}

.level-0 {
  z-index: 0;
  position: relative;
}
.level-1 {
  z-index: 1;
  position: relative;
}

#root {
  height: 100%;
}

.page-content {
  background: white;
  margin-top: 0;
  min-height: calc(100% - 193px);
  padding-top: 30px;
  padding-bottom: 30px;
}

.page-content--minimal {
  min-height: calc(100% - 153px);
  overflow: hidden;
}

.page-content--tight {
  padding-top: 0;
}

.title-themed {
  font-family: "Baloo Thambi 2", sans-serif;
}

@media (max-width: 768px) {
  .btn,
  .btn-group {
    width: 100%;
  }
}

/* Spalash */
.splash-minimal {
  overflow: hidden;
  margin-top: -133px;
  padding-top: 133px;
  height: 153px;
}
.splash-minimal::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: url(./assets/base/wiggle.svg) no-repeat bottom center;
  background-size: 100% 100%;
  z-index: 1;
}

// .splash-minimal::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: url(stars.svg) repeat bottom center;
//   background-size: cover;
//   z-index: 0;
//   opacity: 0.8;
// }

.splash-small {
  overflow: hidden;
  height: 300px;
  margin-top: -133px;
  padding-top: 133px;
  position: relative;
  z-index: 0;
  width: 100%;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    height: 350px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    height: 400px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    height: 400px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    height: 400px;
  }
}

.splash-small::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: url(./assets/base/wiggle.svg) no-repeat bottom center;
  background-size: 100% 100%;
  z-index: 1;
}

// .splash-small::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: url(stars.svg) repeat bottom center;
//   background-size: cover;
//   z-index: 0;
//   opacity: 0.8;
// }

.splash-screen {
  overflow: hidden;
  height: 600px;
  margin-top: -133px;
  padding-top: 133px;
  position: relative;
  z-index: 0;
  width: 100%;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    height: 700px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    height: 800px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    height: 800px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    height: 800px;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px) rotate(20deg);
  }
  25% {
    transform: translatey(-40px) rotate(-20deg);
  }
  50% {
    transform: translatey(0px) rotate(20deg);
  }

  75% {
    transform: translatey(40px) rotate(30deg);
  }

  100% {
    transform: translatey(0px) rotate(20deg);
  }
}

@keyframes blinking {
  0% {
    height: 1px;
    opacity: 0;
  }
  98% {
    height: 1px;
    opacity: 0.6;
  }
  99% {
    height: 53px;
    opacity: 0.7;
  }
  100% {
    height: 1px;
    opacity: 0;
  }
}

.splash-moose {
  background: url("./assets/base/leaping.png") top center no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 70px;
  left: 50%;
  z-index: 2;
  height: 320px;
  margin-left: -150px;
  width: 300px;

  transform: translatey(0px) rotate(20deg);
  animation: float 24s ease-in-out infinite;

  &::before {
    content: "";
    width: 59px;
    height: 53px;
    display: none;
    background: #8e7655;
    position: absolute;
    top: 155px;
    left: 189px;
    border-radius: 26px;

    transform: rotate(-12deg);
    animation: blinking 5s linear infinite;
    opacity: 0.7;

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      display: block;
    }
  }

  &::after {
    content: "";
    width: 59px;
    height: 53px;
    display: none;
    background: #8e7655;
    position: absolute;
    top: 135px;
    left: 240px;
    border-radius: 26px;
    transform: rotate(-12deg);
    animation: blinking 5s linear infinite;
    opacity: 0.7;

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      display: block;
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    height: 419px;
    margin-left: -200px;
    width: 400px;
    bottom: 30px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    height: 469px;
    margin-left: -225px;
    width: 450px;
    bottom: 50px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    height: 469px;
    margin-left: -225px;
    width: 450px;
    bottom: 35px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    height: 469px;
    margin-left: -225px;
    width: 450px;
  }
}

.splash-moon {
  background: url("./assets/base/moon.png") top center no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 282px;
  width: 100%;
  max-width: 100%;
  z-index: 1;
}
@keyframes ripple {
  0% {
    height: 0px;
  }
  100% {
    height: 60px;
  }
}
.splash-screen::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: url(./assets/base/wiggle.svg) no-repeat bottom center;
  background-size: 100% 100%;
  z-index: 1;
  animation-name: ripple;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

// .splash-screen::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: url(stars.svg) repeat bottom center;
//   z-index: 0;
//   opacity: 0.8;
// }

.splash-title {
  font-family: "Baloo Thambi 2", sans-serif;
  font-size: 50px;
  color: #ffc500;
  text-align: center;
  margin-bottom: 0px;
  text-transform: uppercase;
  line-height: 1;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    font-size: 80px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 90px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 100px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    font-size: 100px;
  }
}
.splash-subtitle {
  font-family: "Baloo Thambi 2", sans-serif;
  font-size: 35px;
  color: white;
  text-align: center;
  margin-top: 0px;
  text-transform: uppercase;
  line-height: 0.7;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    font-size: 50px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 60px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 75px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    font-size: 75px;
  }
}

.u-3-lines {
  line-height: 1.5em;
  min-height: 4.5em;
  float: left;
}

.navbar-dark .navbar-nav .nav-link {
  font-family: "Raleway", sans-serif;
  font-weight: 500 !important;
  color: white !important;
  font-size: 1em;
  text-transform: uppercase;
}

.navbar-collapse {
  padding: 20px 0;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    padding: 0;
  }
}

h1,
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 800 !important;
}

/* Product grid */

.product-grid2 {
  font-family: "Open Sans", sans-serif;
  position: relative;
}
.product-grid2 .product-image2 {
  overflow: hidden;
  position: relative;
}
.product-grid2 .product-image2 a {
  display: block;
}
.product-grid2 .product-image2 img {
  width: 100%;
  height: auto;
}
.product-image2 .pic-1 {
  opacity: 1;
  transition: all 0.5s;
}
.product-grid2:hover .product-image2 .pic-1 {
  opacity: 0;
}
.product-image2 .pic-2 {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s;
}
.product-grid2:hover .product-image2 .pic-2 {
  opacity: 1;
}
.product-grid2 .social {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 50px;
  right: 25px;
  z-index: 1;
}
.product-grid2 .social li {
  margin: 0 0 10px;
  display: block;
  transform: translateX(100px);
  transition: all 0.5s;
}
.product-grid2:hover .social li {
  transform: translateX(0);
}
.product-grid2:hover .social li:nth-child(2) {
  transition-delay: 0.15s;
}
.product-grid2:hover .social li:nth-child(3) {
  transition-delay: 0.25s;
}
.product-grid2 .social li a {
  color: #505050;
  background-color: #fff;
  font-size: 17px;
  line-height: 45px;
  text-align: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: block;
  transition: all 0.3s ease 0s;
}
.product-grid2 .social li a:hover {
  color: #fff;
  background-color: #3498db;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.product-grid2 .social li a:after,
.product-grid2 .social li a:before {
  content: attr(data-tip);
  color: #fff;
  background-color: #000;
  font-size: 12px;
  line-height: 22px;
  border-radius: 3px;
  padding: 0 5px;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -30px;
}
.product-grid2 .social li a:after {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -22px;
  z-index: -1;
}
.product-grid2 .social li a:hover:after,
.product-grid2 .social li a:hover:before {
  opacity: 1;
}
.product-grid2 .add-to-cart {
  color: #fff;
  background-color: #404040;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  display: block;
  position: absolute;
  left: 0;
  bottom: -100%;
  transition: all 0.3s;
}
.product-grid2 .add-to-cart:hover {
  background-color: #3498db;
  text-decoration: none;
}
.product-grid2:hover .add-to-cart {
  bottom: 0;
}
.product-grid2 .product-new-label {
  background-color: #3498db;
  color: #fff;
  font-size: 17px;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s;
}
.product-grid2:hover .product-new-label {
  opacity: 0;
}
.product-grid2 .product-content {
  padding: 20px 10px;
  text-align: center;
}
.product-grid2 .title {
  font-size: 17px;
  margin: 0 0 7px;
}
.product-grid2 .title a {
  color: #303030;
}
.product-grid2 .title a:hover {
  color: #3498db;
}
.product-grid2 .price {
  color: #303030;
  font-size: 15px;
  margin: 0 0 7px;
}

.mini-colour-summary {
  margin: 0 0 7px 0;
}

.mini-colour-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  max-width: 80%;
  margin: 0 auto;
}

.mini-colour {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-indent: -999999em;
  margin: 2px;
  border: 1px solid grey;
  padding: 0;
}

@media screen and (max-width: 990px) {
  .product-grid2 {
    margin-bottom: 30px;
  }
}
.custom-radio-wrapper {
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}

.custom-radio-wrapper label {
  cursor: pointer;
}

.custom-colour {
  display: inline-block;
  padding: 0;
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-left: 0;
}

.custom-colour .custom-control-label {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  text-indent: -9999em;
}

.custom-colour .custom-control-label::after,
.custom-colour .custom-control-label::before {
  left: 0 !important;
  top: 0 !important;
  border-radius: 0;
  background: none !important;
}

.custom-colour input[type="radio"] + label:after {
  border: 3px solid grey;
  background: none !important;

  width: 50px;
  height: 50px;
}

.custom-colour input[type="radio"]:checked + label:after {
  border: 3px solid #28a745;
}

/* CSS pseudo-element styling */

/* general reset/setup styling for icons - needed on all */
.custom-colour input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  background: none;
  line-height: 50px;
  border: none;
  width: 50px;
  height: 50px;
  font-family: "Font Awesome 5 Pro";
  text-indent: 0;
  font-weight: 900;
  text-align: center;
}

/* defining the right unicode values for each icon */
.custom-colour input[type="radio"]:checked + label:before {
  content: "\f00c";
}

.quantity {
  float: left;
  margin-right: 15px;
  background-color: #eee;
  position: relative;
  width: 80px;
  overflow: hidden;
}

.quantity input {
  margin: 0;
  text-align: center;
  width: 15px;
  height: 15px;
  padding: 0;
  float: right;
  color: #000;
  font-size: 20px;
  border: 0;
  outline: 0;
  background-color: #f6f6f6;
}

.quantity input.qty {
  position: relative;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 10px 25px 10px 10px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  border-radius: 0;
  background-clip: padding-box;
}

.quantity .minus,
.quantity .plus {
  line-height: 0;
  background-clip: padding-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-size: 6px 30px;
  -moz-background-size: 6px 30px;
  color: #bbb;
  font-size: 20px;
  position: absolute;
  height: 50%;
  border: 0;
  right: 0;
  padding: 0;
  width: 25px;
  z-index: 3;
}

.quantity .minus:hover,
.quantity .plus:hover {
  background-color: #dad8da;
}

.quantity .minus {
  bottom: 0;
}
.shopping-cart {
  margin-top: 20px;
}

.product-name {
  color: black;
}

.breadcrumb {
  background-color: transparent !important;
  padding: 0 0 10px 0 !important;
}

.breadcrumb-item {
  color: black !important;
  font-weight: bold;
}
.breadcrumb-item.active {
  color: black !important;
  font-weight: normal;
}

.error-message {
  color: red;
  padding: 10px 0;
}

// custom shiping

.custom-shipping {
  padding: 10px 0;
  font-size: 16px;
}

.embedsocial-btn-style-outline {
  background-color: red !important;
}
