@keyframes bounce-4 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.GameSquare {
  position: relative;
}

.GridOverlay {
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  z-index: 9;
  border-radius: 20px;
  text-align: center;
  padding: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.GridTitle {
  font-family: "Baloo Thambi 2", sans-serif;
  color: #010e15;
  margin-bottom: 0px;
  text-transform: uppercase;
  line-height: 0.8;
  text-align: center;
  width: 100%;

  @media (max-width: 61rem) {
    font-size: 50px;
  }

  @media (min-width: 62rem) {
    font-size: 90px;
  }
}

.GridSubTitle {
  font-family: "Baloo Thambi 2", sans-serif;
  color: #ffc500;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  line-height: 0.8;
  width: 100%;

  @media (max-width: 61rem) {
    font-size: 40px;
  }

  @media (min-width: 62rem) {
    font-size: 50px;
  }
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  clear: both;
  position: relative;
}

.GridItem {
  width: 100%;
  margin-bottom: 2%;

  @media (max-width: 61rem) {
    width: 32%;
    &:not(:nth-child(3n)) {
      margin-right: 2%;
    }
  }

  @media (min-width: 62rem) {
    width: 23.5%;
    &:not(:nth-child(4n)) {
      margin-right: 2%;
    }
  }

  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }
}

.flipCard {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  cursor: pointer;
}

/* This container is needed to position the front and back side */
.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipCardActive .flipCardInner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

/* Style the front side (fallback if image is missing) */
.flipCardFront {
  background: url(../../assets/matching/back.jpg) 100% 100%;
  background-size: 100% 100%;
  color: black;

  @media (max-width: 61rem) {
    border: 5px solid white;
  }

  @media (min-width: 62rem) {
    border: 10px solid white;
  }
}

/* Style the back side */
.flipCardBack {
  color: white;
  transform: rotateY(180deg);
  box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.4);

  img {
    width: 100%;
  }
}

.matched {
  animation-name: bounce-4;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: 2;
}
